const VOUCHERS = {
  SAVE20: {
    discount: 0.2,
    type: 'percentage',
  },
  SAVE50: {
    discount: 0.5,
    type: 'percentage',
  },
  FLAT25: {
    discount: 25,
    type: 'fixed',
  },
};

export default VOUCHERS;
