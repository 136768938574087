import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Home from './pages/Home';
import Login from './pages/Login';
import OrderConfirmation from './pages/OrderConfirmation';
import Customize from './pages/Customize';
import Reset from './pages/Reset';
import Checkout from './pages/Checkout';
import ROUTES from './configs/routes';
import Orders from './pages/Orders';

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#009E7F',
    },
    secondary: {
      main: '#CACACA',
    },
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItems: {},
      cartTotal: 0,
      user: {},
      isUserLoggedIn: false,
    };
  }

  updateCart = (items, total) => {
    this.setState({
      cartItems: items,
      cartTotal: total,
    });
  };

  updateUser = userData => {
    this.setState({
      user: userData,
      isUserLoggedIn: Object.keys(userData).length > 0,
    });
  };

  handleLogout = () => {
    this.setState({
      user: {},
      isUserLoggedIn: false,
    });
  };

  render() {
    const { cartItems, cartTotal, user, isUserLoggedIn } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path={ROUTES.HOMEPAGE}
              render={props => (
                <Home
                  {...props}
                  cartItems={cartItems}
                  cartTotal={cartTotal}
                  updateCart={this.updateCart}
                  user={user}
                  isUserLoggedIn={isUserLoggedIn}
                  updateUser={this.updateUser}
                  onLogout={this.handleLogout}
                />
              )}
            />
            <Route path={ROUTES.LOGIN} render={props => <Login {...props} updateUser={this.updateUser} />} />
            <Route path={ROUTES.ORDER_CONFIRMATION} render={props => <OrderConfirmation {...props} user={user} />} />
            <Route path={ROUTES.CUSTOMIZE} component={Customize} />
            <Route path={ROUTES.RESET} component={Reset} />
            <Route
              path={ROUTES.CHECKOUT}
              render={props => (
                <Checkout
                  {...props}
                  cartItems={cartItems}
                  cartTotal={cartTotal}
                  user={user}
                  updateCart={this.updateCart}
                />
              )}
            />
            <Route
              path={ROUTES.ORDERS}
              render={props => (isUserLoggedIn ? <Orders {...props} user={user} /> : <Redirect to={ROUTES.LOGIN} />)}
            />
            <Route name="404" path="*" render={() => <Redirect to={ROUTES.HOMEPAGE} />} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;
