import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ROUTES from '../configs/routes';
import PRODUCTS from '../configs/products';

import LeftNav from '../components/LeftNav';
import Category from '../components/Category';
import Header from '../components/Header';
import CartPreview from '../components/CartPreview';
import CartModal from '../components/CartModal';
import ProductModal from '../components/ProductModal';
import SearchBar from '../components/SearchBar';

function Home(props) {
  const { history, cartItems, cartTotal, updateCart, user, isUserLoggedIn, onLogout } = props;

  const [state, setState] = useState({
    selectedCategory: 'boots',
    selectedProductForView: {},
    searchTerm: '',
  });

  useEffect(() => {
    const waitForAnalytics = setInterval(() => {
      const analytics = (window && window.metarouter) || (window && window.analytics);
      if (analytics) {
        clearInterval(waitForAnalytics);

        // Let automatic page view handle basic page info
        // Add our contextual page view with category and name
        analytics.page(
          'Homepage',
          'Homepage',
          {
            path: '/shop',
            title: 'metashop',
            url: window.location.href,
            category: 'Homepage',
          },
          {
            traits: {
              name: user.name,
              email: user.email,
            },
          }
        );

        // Product List Viewed for initial boots category
        const productsToLoop = PRODUCTS.boots;
        const prods = [];
        productsToLoop.forEach(prod => {
          prods.push({
            product_id: prod.id,
            sku: prod.id,
            name: prod.name,
            price: prod.price,
            image_url: prod.image,
            category: prod.category,
            brand: prod.brand,
          });
        });
        analytics.track(
          'Product List Viewed',
          {
            category: productsToLoop[0]?.category || 'Boots',
            list_id: `${productsToLoop[0]?.category.toLowerCase()}_collection` || 'boots_collection',
            products: prods,
          },
          {
            traits: {
              name: user.name,
              email: user.email,
            },
          }
        );
      }
    }, 100);

    return () => clearInterval(waitForAnalytics);
  }, []);

  const onSelectCategory = category => {
    if (category !== state.selectedCategory) {
      const analytics = (window && window.metarouter) || (window && window.analytics);
      if (analytics) {
        const productsToLoop =
          PRODUCTS && PRODUCTS[category] && PRODUCTS[category].length > 0 ? PRODUCTS[category] : PRODUCTS.boots;
        const prods = [];
        productsToLoop.forEach(prod => {
          prods.push({
            product_id: prod.id,
            sku: prod.id,
            name: prod.name,
            price: prod.price,
            image_url: prod.image,
            category: prod.category,
            brand: prod.brand,
          });
        });
        analytics.track(
          'Product List Viewed',
          {
            category: productsToLoop[0]?.category || 'Boots',
            list_id: `${productsToLoop[0]?.category.toLowerCase()}_collection` || 'boots_collection',
            products: prods,
          },
          {
            traits: {
              name: user.name,
              email: user.email,
            },
          }
        );
      }
    }
    state.selectedCategory = category;
    setState({ ...state });
  };

  const handleLogout = () => {
    onLogout();
    history.push(ROUTES.HOMEPAGE);
  };

  const onAddToCart = product => {
    const analytics = (window && window.metarouter) || (window && window.analytics);
    if (analytics) {
      analytics.track(
        'Product Added',
        {
          product_id: product.id,
          sku: product.id,
          name: product.name,
          price: product.price,
          quantity: 1,
          image_url: product.image,
          category: product.category,
          brand: product.brand,
          url: window.location.href,
        },
        {
          traits: {
            name: user.name,
            email: user.email,
          },
        }
      );
    }

    const updatedCartItems = { ...cartItems };

    if (updatedCartItems[product.id]) {
      updatedCartItems[product.id] = {
        ...updatedCartItems[product.id],
        quantity: updatedCartItems[product.id].quantity + 1,
      };
    } else {
      updatedCartItems[product.id] = {
        id: product.id,
        name: product.name,
        image: product.image,
        price: product.price,
        category: product.category,
        brand: product.brand,
        quantity: 1,
      };
    }

    const amount = Object.values(updatedCartItems).reduce((total, item) => total + item.price * item.quantity, 0);

    updateCart(updatedCartItems, amount);
  };

  const onJoin = () => {
    history.push(ROUTES.LOGIN);
  };

  const [cartOpen, setCartOpen] = React.useState(false);
  const [productOpen, setProductOpen] = React.useState(false);

  const handleCartOpen = () => {
    const analytics = (window && window.metarouter) || (window && window.analytics);
    if (analytics) {
      const prods = [];
      Object.keys(cartItems).forEach(key => {
        prods.push({
          product_id: cartItems[key].id,
          sku: cartItems[key].id,
          name: cartItems[key].name,
          price: cartItems[key].price,
          quantity: cartItems[key].quantity,
          image_url: cartItems[key].image,
          category: cartItems[key].category,
          brand: cartItems[key].brand,
          url: window.location.href,
        });
      });
      analytics.track(
        'Cart Viewed',
        {
          cart_id: `cart_${Date.now()}`,
          currency: 'USD',
          products: prods,
        },
        {
          traits: {
            name: user.name,
            email: user.email,
          },
        }
      );
    }
    setCartOpen(true);
  };
  const handleProductOpen = product => {
    const analytics = (window && window.metarouter) || (window && window.analytics);
    if (analytics) {
      // Track Product Clicked event
      analytics.track(
        'Product Clicked',
        {
          product_id: product.id,
          sku: product.id,
          name: product.name,
          price: product.price,
          image_url: product.image,
          category: product.category,
          brand: product.brand,
          url: window.location.href,
        },
        {
          traits: {
            name: user.name,
            email: user.email,
          },
        }
      );

      // Track Product Viewed event (existing)
      analytics.track(
        'Product Viewed',
        {
          product_id: product.id,
          sku: product.id,
          name: product.name,
          price: product.price,
          image_url: product.image,
          category: product.category,
          brand: product.brand,
          url: window.location.href,
        },
        {
          traits: {
            name: user.name,
            email: user.email,
          },
        }
      );
    }
    state.selectedProductForView = product;
    setState({ ...state });
    setProductOpen(true);
  };

  const handleCartClose = () => {
    setCartOpen(false);
  };
  const handleProductClose = () => {
    state.selectedProductForView = {};
    setState({ ...state });
    setProductOpen(false);
  };

  const onCheckout = () => {
    history.push(ROUTES.CHECKOUT);
  };

  const handleSearch = searchTerm => {
    setState(prevState => ({
      ...prevState,
      searchTerm: searchTerm,
    }));
  };

  // Add useEffect to reset search when category changes
  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      searchTerm: '',
    }));
  }, [state.selectedCategory]);

  const onRemoveFromCart = productId => {
    const analytics = (window && window.metarouter) || (window && window.analytics);
    const removedProduct = cartItems[productId];

    if (analytics && removedProduct) {
      analytics.track('Product Removed', {
        product_id: removedProduct.id,
        sku: removedProduct.id,
        name: removedProduct.name,
        price: removedProduct.price,
        quantity: removedProduct.quantity,
        image_url: removedProduct.image,
        category: removedProduct.category,
        brand: removedProduct.brand,
        url: window.location.href,
      });
    }

    const updatedCartItems = { ...cartItems };
    delete updatedCartItems[productId];

    const amount = Object.values(updatedCartItems).reduce((total, item) => total + item.price * item.quantity, 0);

    updateCart(updatedCartItems, amount);
  };

  // const analytics = (window && window.metarouter) || (window && window.analytics);
  // analytics.page();

  return (
    <div className="main">
      <LeftNav
        selectedCategory={state.selectedCategory}
        isUserLoggedIn={isUserLoggedIn}
        onSelectCategory={onSelectCategory}
        onLogout={handleLogout}
      />
      <div className="category-main-containter">
        <SearchBar onSearch={handleSearch} />
        <Category
          category={state.selectedCategory}
          isUserLoggedIn={isUserLoggedIn}
          onAddToCart={onAddToCart}
          handleOpen={handleProductOpen}
          searchTerm={state.searchTerm}
          user={user}
        />
      </div>
      <Header isUserLoggedIn={isUserLoggedIn} onJoin={onJoin} />
      <CartPreview cartItems={cartItems} cartTotal={cartTotal} handleOpen={handleCartOpen} />
      <CartModal
        cartItems={cartItems}
        cartTotal={cartTotal}
        open={cartOpen}
        handleClose={handleCartClose}
        onCheckout={onCheckout}
        onRemoveFromCart={onRemoveFromCart}
      />
      <ProductModal
        isUserLoggedIn={isUserLoggedIn}
        product={state.selectedProductForView}
        open={productOpen}
        handleClose={handleProductClose}
        onAddToCart={onAddToCart}
      />
    </div>
  );
}

Home.defaultProps = {
  history: {
    location: {
      state: {},
    },
  },
};

Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.object,
  }).isRequired,
  cartItems: PropTypes.object.isRequired,
  cartTotal: PropTypes.number.isRequired,
  updateCart: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default Home;
