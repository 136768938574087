import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ROUTES from '../configs/routes';

function OrderConfirmation({ history, user }) {
  useEffect(() => {
    const analytics = (window && window.metarouter) || (window && window.analytics);
    if (analytics) {
      analytics.page(
        'Order Confirmation',
        'Order Confirmation',
        {
          path: '/order-confirmation',
          title: 'Order Confirmation - Metashop',
          url: window.location.href,
          category: 'Order Confirmation',
        },
        user?.name && user?.email
          ? {
              traits: {
                name: user.name,
                email: user.email,
              },
            }
          : undefined
      );
    }
  }, [user]);

  const onGoBack = () => {
    history.replace(ROUTES.HOMEPAGE);
  };

  return (
    <div className="login-page">
      <div className="thank-you-header-title">Thank you for your order!</div>
      <div className="join-btn" onClick={() => onGoBack()}>
        Back to shop
      </div>
    </div>
  );
}

OrderConfirmation.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default OrderConfirmation;
