import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
// import { useHistory } from 'react-router-dom';

// import VOUCHERS from '../configs/vouchers';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: 100,
    right: 0,
    left: 0,
    width: 500,
    maxHeight: 'calc(100% - 200px)',
    margin: '0 auto',
    paddingBottom: '10px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #009E7F',
    borderRadius: '6px',
    outline: 'none',
    overflow: 'scroll',
  },
}));

function CartModal(props) {
  const { cartItems, cartTotal, open, handleClose, onCheckout, onRemoveFromCart } = props;
  // const [state, setState] = useState({
  //   voucher: '',
  //   voucherApplied: false,
  // });

  const classes = useStyles();
  // const history = useHistory();

  // Handle product removal and auto-close if cart becomes empty
  const handleRemoveProduct = productId => {
    onRemoveFromCart(productId);
    if (Object.keys(cartItems).length === 1) {
      // If this is the last item
      handleClose();
    }
  };

  const cartProducts = [];
  Object.keys(cartItems).forEach((key, index) => {
    cartProducts.push(
      <div key={index} className="cart-modal-item-container">
        <div className="cart-modal-item-image-container">
          <img className="cart-modal-item-image" alt={cartItems[key].name} src={cartItems[key].image} />
        </div>
        <div className="cart-modal-item-info-container">
          <span className="cart-modal-item-info-name">{cartItems[key].name}</span>
          <span className="cart-modal-item-info-weight">
            {cartItems[key].quantity}
            &nbsp;X&nbsp;
          </span>
          <span className="cart-modal-item-info-price">${cartItems[key].price.toFixed(2)}</span>
        </div>
        <div className="cart-modal-item-total">
          ${(cartItems[key].quantity * cartItems[key].price).toFixed(2)}
          <button onClick={() => handleRemoveProduct(key)} className="cart-modal-item-remove-btn">
            Remove
          </button>
        </div>
      </div>
    );
  });

  // const onVoucherChange = () => (event) => {
  //   const { value } = event.target;
  //   state.voucher = value.toUpperCase();
  //   setState({ ...state });
  // };

  // const onApplyVoucher = () => {
  //   const { voucher } = state;
  //   if (voucher && VOUCHERS[voucher] !== null && VOUCHERS[voucher] !== undefined) {
  //     state.voucherApplied = true;
  //     setState({ ...state });
  //   }
  // }

  const handleCheckoutClick = total => {
    if (onCheckout) {
      onCheckout(total);
    }
    handleClose();
  };

  const body = (
    <div className={classes.paper}>
      <div className="cart-modal-header">
        <div className="cart-modal-header-items-count">
          <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="24px" viewBox="0 0 23.786 30">
            <g data-name="shopping-bag (3)" transform="translate(-53.023)">
              <g data-name="Group 2704">
                <g data-name="Group 17" transform="translate(53.023 5.918)">
                  <g data-name="Group 16">
                    <path
                      data-name="Path 3"
                      d="M76.8,119.826l-1.34-16.881A2.109,2.109,0,0,0,73.362,101H70.716v3.921a.879.879,0,1,1-1.757,0V101H60.875v3.921a.879.879,0,1,1-1.757,0V101H56.472a2.109,2.109,0,0,0-2.094,1.937l-1.34,16.886a4.885,4.885,0,0,0,4.87,
                      5.259H71.926a4.884,4.884,0,0,0,4.87-5.261Zm-7.92-8.6-4.544,4.544a.878.878,0,0,1-1.243,0l-2.13-2.13A.878.878,0,0,1,62.2,112.4l1.509,1.508,3.923-3.923a.879.879,0,1,1,1.242,1.243Z"
                      transform="translate(-53.023 -101.005)"
                      fill="currentColor"
                    />
                  </g>
                </g>
                <g data-name="Group 19" transform="translate(59.118)">
                  <g data-name="Group 18">
                    <path
                      data-name="Path 4"
                      d="M162.838,0a5.806,5.806,0,0,0-5.8,5.8v.119H158.8V5.8a4.042,4.042,0,1,1,8.083,0v.119h1.757V5.8A5.806,5.806,0,0,0,162.838,0Z"
                      transform="translate(-157.039)"
                      fill="currentColor"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            {Object.keys(cartItems).length}
            &nbsp;
            {Object.keys(cartItems).length > 1 ? 'Items' : 'Item'}
          </span>
        </div>
      </div>
      <div className="card-modal-content">{cartProducts}</div>
      <div className="card-modal-footer">
        <button className="card-modal-footer-checkout-btn" onClick={() => handleCheckoutClick(cartTotal.toFixed(2))}>
          <span className="card-modal-footer-checkout-btn-text">Checkout</span>
          <span className="card-modal-footer-checkout-amount">${cartTotal.toFixed(2)}</span>
        </button>
      </div>
    </div>
  );

  return (
    <Modal
      className="cart-content-modal"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
}

CartModal.propTypes = {
  cartItems: PropTypes.shape({}).isRequired,
  cartTotal: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCheckout: PropTypes.func.isRequired,
  onRemoveFromCart: PropTypes.func.isRequired,
};

export default CartModal;
