import React, { useState } from 'react';
import PropTypes from 'prop-types';
import lifecycle from 'react-pure-lifecycle';
import TextField from '@material-ui/core/TextField';

import ROUTES from '../configs/routes';
import MetaShop from '../assets/metashop.png';

const componentDidMount = () => {
  const analytics = (window && window.metarouter) || (window && window.analytics);
  if (analytics) analytics.page('Login');
};

const methods = {
  componentDidMount,
};

function Login(props) {
  const [state, setState] = useState({
    name: '',
    email: '',
  });

  const onTextFieldChange = field => event => {
    const { value } = event.target;
    state[field] = value;
    setState({ ...state });
  };

  const onJoin = () => {
    const { history, updateUser } = props;
    const analytics = (window && window.metarouter) || (window && window.analytics);
    if (analytics) {
      analytics.identify(state.email, {
        name: state.name,
        email: state.email,
      });
    }
    updateUser(state);
    history.push({
      pathname: ROUTES.HOMEPAGE,
      state,
    });
  };

  return (
    <div className="login-page">
      <img className="login-header-logo" src={MetaShop} alt="MetaShop Logo" />
      <div className="login-card">
        <TextField
          id="name"
          className="text-field-name"
          value={state.name}
          onChange={onTextFieldChange('name')}
          label="Name"
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="email"
          className="text-field-email"
          value={state.email}
          onChange={onTextFieldChange('email')}
          label="Email"
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div className="join-btn" onClick={() => onJoin()}>
          Join
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default lifecycle(methods)(Login);
