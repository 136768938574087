import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useDropzone } from 'react-dropzone';
import { saveFileToDB, saveTextToDB } from '../configs/indexedDB';

import MetaShop from '../assets/metashop.png';
import Upload from '../assets/Upload.png';
import UploadDone from '../assets/Upload_Done.png';

// const componentDidMount = () => {
//   const analytics = (window && window.metarouter) || (window && window.analytics);
//   if (!analytics) alert("Analytics.js is not detected");
// };

// const methods = {
//   componentDidMount,
// };

function Customize() {
  const [state, setState] = useState({
    ajs: window.getCookie('ajs'),
    writekey: window.getCookie('writekey'),
    preset: window.getCookie('preset'),
    advanced: false,
    localAJS: false,
    ajsFile: null,
    url: '',
    csTags: '',
  });

  const onTextFieldChange = field => event => {
    const { value } = event.target;
    state[field] = value;
    setState({ ...state });
  };

  const toggleAdvanced = () => {
    state.advanced = !state.advanced;
    state.localAJS = false;
    setState({ ...state });
  };

  const toggleLocalAJS = () => {
    state.localAJS = !state.localAJS;
    state.advanced = false;
    setState({ ...state });
  };

  const generateLink = () => {
    let params = '/shop?';
    if (!state.advanced && !state.localAJS) {
      window.setCookie('preset', state.preset, 365);
      params = params.concat(
        `ajs=${encodeURIComponent('https://cdn.metarouter.io/demo-app/mr-demo-' + state.preset + '-analytics.js')}`,
        '&',
        `writekey=mr-demo-${state.preset}`
      );
    } else {
      let param = '';
      if (state.writekey) {
        param = param + `ajs=${encodeURIComponent(state.ajs)}`;
      }
      if (state.writekey && state.ajs) {
        param = param + '&';
      }
      if (state.ajs) {
        param = param + `writekey=${state.writekey}`;
      }
      console.log(state);
      params = params.concat(param);
    }
    return params;
  };

  const onCustomize = () => {
    console.log('Advanced Mode: ', state.advanced ? 'ON' : 'OFF');
    window.location.href = generateLink();
  };

  const onShare = clear => {
    if (clear) {
      state.url = '';
    } else {
      state.url = window.location.origin + generateLink();
    }
    setState({ ...state });
  };

  const onDrop = acceptedFiles => {
    if (acceptedFiles && acceptedFiles[0]) {
      state.ajsFile = true;
      saveFileToDB(acceptedFiles[0], 'local-ajs.js');
      setState({ ...state });
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const addCustomScriptToHead = () => {
    saveTextToDB('custom-tags', state.csTags);
    window.location.href = '/customize';
  };

  return (
    <div className="customize-page">
      <img className="customize-header-logo" src={MetaShop} alt="MetaShop" />
      <div className="customize-card">
        <div className={`${state.advanced || state.localAJS ? 'hidden' : ''}`}>
          <h2>MetaRouter Demo Key</h2>
          <TextField
            className="text-field-name"
            value={state.preset}
            onChange={onTextFieldChange('preset')}
            label="Demo Key"
            variant="standard"
          />
        </div>
        <div className={`${!state.advanced ? 'hidden' : ''}`}>
          <h2>Advanced Demo Settings</h2>
          <TextField
            className="text-field-name"
            value={state.ajs}
            onChange={onTextFieldChange('ajs')}
            label="AJS URL"
            variant="standard"
          />
          <TextField
            className="text-field-email"
            value={state.writekey}
            onChange={onTextFieldChange('writekey')}
            label="Write Key"
            variant="standard"
          />
        </div>
        <div className={`${!state.localAJS ? 'hidden' : ''}`}>
          <h2>Upload your AJS file</h2>
          <div className="analytics-js-dropzone" {...getRootProps()}>
            <input {...getInputProps()} accept="text/javascript" multiple={false} />
            <img src={state.ajsFile ? UploadDone : Upload} height="54px" alt="Upload your analytics.js file" />
          </div>
        </div>
        <br />
        <div className="customize-btn" onClick={() => onCustomize()}>
          Start a Demo
        </div>
        <div onClick={() => toggleAdvanced()} className={`${state.advanced ? 'active' : ''} settings-btn`}>
          {!state.advanced ? 'Show' : 'Hide'} Advanced Settings
        </div>
        <div onClick={() => toggleLocalAJS()} className={`${state.localAJS ? 'active' : ''} settings-btn`}>
          {!state.localAJS ? 'Show' : 'Hide'} Local AJS Upload
        </div>
        <br />
        <br />
        <div>
          <h3>Have any Client-Side Tags to add?</h3>
          <TextField
            className="text-field-name"
            value={state.csTags}
            onChange={onTextFieldChange('csTags')}
            label="Tags"
            helperText="Do not include the <script> tag. Multiple tags can be included."
            minRows={1}
            multiline={true}
          />
        </div>
        <br />
        <div className="customize-btn" onClick={() => addCustomScriptToHead()}>
          Attach Script to Head
        </div>
        <br />
        <Link className="reset-all-btn" to="/reset">
          Reset All Settings
        </Link>
      </div>
      <div className={`${!state.advanced ? 'hidden' : ''} customize-card`}>
        <p className={`${!state.url ? 'hidden' : ''} share-text`}>{state.url}</p>
        <div className={`${state.url ? 'hidden' : ''} customize-btn`} onClick={() => onShare()}>
          Generate Share Link
        </div>
        <div className={`${!state.url ? 'hidden' : ''} customize-btn`} onClick={() => onShare(true)}>
          Hide Share Link
        </div>
      </div>
    </div>
  );
}

Customize.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Customize;
