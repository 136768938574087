const ROUTES = {
  HOMEPAGE: '/shop',
  LOGIN: '/login',
  ORDER_CONFIRMATION: '/order-confirmation',
  CUSTOMIZE: '/customize',
  RESET: '/reset',
  CHECKOUT: '/checkout',
  ORDERS: '/orders',
};

export default ROUTES;
