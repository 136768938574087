import React from 'react';
import PropTypes from 'prop-types';

function LeftNav(props) {
  const {
    selectedCategory,
    isUserLoggedIn,
    onSelectCategory,
    onLogout,
  } = props;

  return (
    <div className="left-nav">
      <div
        className={`left-nav-item ${selectedCategory === 'boots' ? 'selected' : ''}`}
        onClick={() => onSelectCategory('boots')}
      >
        Boots
      </div>
      <div
        className={`left-nav-item ${selectedCategory === 'tents' ? 'selected' : ''}`}
        onClick={() => onSelectCategory('tents')}
      >
        Tents
      </div>
      <div
        className={`left-nav-item ${selectedCategory === 'packs' ? 'selected' : ''}`}
        onClick={() => onSelectCategory('packs')}
      >
        Packs
      </div>
      { isUserLoggedIn && <div className="logout-button" onClick={() => onLogout()}>Log out</div> }
    </div>
  );
}

LeftNav.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  onSelectCategory: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default LeftNav;
