import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 400,
    marginBottom: theme.spacing(3),
    backgroundColor: '#f7f7f7',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    color: '#009E7F',
  },
}));

function SearchBar({ onSearch }) {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = e => {
    e.preventDefault();
    // Track the search event only if there's a search term
    if (searchTerm.trim()) {
      const analytics = (window && window.metarouter) || (window && window.analytics);
      if (analytics) {
        analytics.track('Products Searched', {
          query: searchTerm,
        });
      }
    }
    // Always call onSearch, even with empty string to reset
    onSearch(searchTerm.trim());
  };

  const handleClear = () => {
    setSearchTerm('');
    onSearch(''); // Reset search
  };

  return (
    <Paper component="form" className={classes.root} onSubmit={handleSearch}>
      <InputBase
        className={classes.input}
        placeholder="Search products..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
      {searchTerm && (
        <IconButton className={classes.iconButton} onClick={handleClear} aria-label="clear">
          <ClearIcon />
        </IconButton>
      )}
      <IconButton type="submit" className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchBar;
