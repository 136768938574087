import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import PRODUCTS from '../configs/products';

function Category(props) {
  const { category, onAddToCart, handleOpen, searchTerm } = props;
  const productsToDisplay =
    PRODUCTS && PRODUCTS[category] && PRODUCTS[category].length > 0 ? PRODUCTS[category] : PRODUCTS['women-dress'];

  // Track promotion view when tent category is viewed
  useEffect(() => {
    if (category === 'tents') {
      const analytics = (window && window.metarouter) || (window && window.analytics);
      if (analytics) {
        analytics.track('Promotion Viewed', {
          promotion_id: 'promo_dog_tent',
          creative: 'tent_category_banner',
          name: 'Dog Tent Special Offer',
          position: 'category_top',
        });
      }
    }
  }, [category]);

  const handlePromotionClick = () => {
    const analytics = (window && window.metarouter) || (window && window.analytics);
    if (analytics) {
      analytics.track('Promotion Clicked', {
        promotion_id: 'promo_dog_tent',
        creative: 'tent_category_banner',
        name: 'Dog Tent Special Offer',
        position: 'category_top',
      });
    }
    // You could add navigation here if needed
  };

  // Filter products based on search term within the current category only
  const filteredProducts = searchTerm
    ? productsToDisplay.filter(
        product =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.brand.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : productsToDisplay;

  const handleWishlist = product => {
    const analytics = (window && window.metarouter) || (window && window.analytics);
    if (analytics) {
      analytics.track('Product Added to Wishlist', {
        wishlist_id: 'default-wishlist',
        wishlist_name: 'My Wishlist',
        product_id: product.id,
        sku: product.id,
        category: product.category,
        name: product.name,
        brand: product.brand,
        price: product.price,
        quantity: 1,
        url: window.location.href,
        image_url: product.image,
      });
    }
  };

  return (
    <div className="category-main">
      {category === 'tents' && (
        <div className="promotion-banner" onClick={handlePromotionClick}>
          <img
            src="https://www.k9sportsack.com/cdn/shop/files/K9SS1533_1_2048x2048.jpg?v=1727130368"
            alt="Dog Tent Promotion"
            className="promotion-image"
          />
          <div className="promotion-content">
            <h3>NEW! Dog Camping Tents</h3>
            <p>Special Launch Offer - 20% Off!</p>
          </div>
        </div>
      )}
      {filteredProducts.length > 0 ? (
        <div>
          {filteredProducts.map((product, index) => (
            <div key={index} className={`product-card product-number-${index}`}>
              <div className="product-card-container">
                <img
                  alt={product.name}
                  src={product.image}
                  className="product-card-image"
                  onClick={() => handleOpen(product)}
                />
                <div className="product-card-name" onClick={() => handleOpen(product)}>
                  {product.name}
                </div>
                <div className="product-card-brand">{product.brand}</div>
                <div className="product-card-footer">
                  <div className="product-card-price-wrapper">
                    <span className="product-card-sale-price">${product.price}</span>
                  </div>
                  <div className="product-card-actions">
                    <button type="button" className="product-card-wishlist-btn" onClick={() => handleWishlist(product)}>
                      <span className="btn-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                          />
                        </svg>
                      </span>
                    </button>
                    <button
                      type="button"
                      className="product-card-cart-btn"
                      onClick={() => {
                        onAddToCart(product);
                      }}
                      role="button"
                      aria-label="Add to Cart"
                    >
                      <span className="btn-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.4" height="12" viewBox="0 0 14.4 12">
                          <g data-name="Group 120" transform="translate(-288 -413.89)">
                            <path
                              data-name="Path 154"
                              fill="currentColor"
                              d="M298.7,418.289l-2.906-4.148a.835.835,0,0,0-.528-.251.607.607,0,0,0-.529.251l-2.905,4.148h-3.17a.609.609,0,0,0-.661.625v.191l1.651,5.84a1.336,1.336,0,0,0,1.255.945h8.588a1.261,1.261,0,0,0,1.254-.945l1.651-5.84v-.191a.609.609,0,0,0-.661-.625Zm-5.419,0,1.984-2.767,1.98,2.767Zm1.984,5.024a1.258,1.258,0,1,1,1.319-1.258,1.3,1.3,0,0,1-1.319,1.258Zm0,0"
                            />
                          </g>
                        </svg>
                      </span>
                      <span className="btn-text">&nbsp;&nbsp;Cart</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          No products found in this category matching &quot;{searchTerm}&quot;
        </div>
      )}
    </div>
  );
}

Category.propTypes = {
  category: PropTypes.string.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
};

export default Category;
