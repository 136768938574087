import { openDB } from 'idb';

const initDB = async () => {
  const db = await openDB('metashop', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('entries')) {
        db.createObjectStore('entries', { keyPath: 'id', autoIncrement: true });
      }
    },
  });
  return db;
};

export const saveFileToDB = async (file, fileName) => {
  const db = await initDB();
  const tx = db.transaction('entries', 'readwrite');
  const store = tx.objectStore('entries');
  await store.put({ id: fileName, file });
  await tx.done;
};

export const getFileFromDB = async fileName => {
  const db = await initDB();
  const tx = db.transaction('entries', 'readonly');
  const store = tx.objectStore('entries');
  return await store.get(fileName);
};

export const saveTextToDB = async (id, text) => {
  const db = await initDB();
  const tx = db.transaction('entries', 'readwrite');
  const store = tx.objectStore('entries');
  await store.put({ id, text }); // Storing long text with an id
  await tx.done;
};

export const getTextFromDB = async id => {
  const db = await initDB();
  const tx = db.transaction('entries', 'readonly');
  const store = tx.objectStore('entries');
  const request = store.get(id);
  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve(request.result?.text || null);
    request.onerror = () => reject('Error retrieving text');
  });
};

export const deleteEntryFromDB = async id => {
  const db = await initDB();
  const tx = db.transaction('entries', 'readwrite');
  const store = tx.objectStore('entries');
  const request = store.delete(id);
  return new Promise((resolve, reject) => {
    request.onsuccess = () => {
      console.log(`Entry with ID ${id} has been deleted.`);
      resolve();
    };
    request.onerror = () => reject('Error deleting entry');
  });
};
