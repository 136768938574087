import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button, Grid, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import ROUTES from '../configs/routes';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    maxWidth: 1200,
    margin: '0 auto',
  },
  orderCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actionButton: {
    marginRight: theme.spacing(1),
  },
  orderDetails: {
    marginTop: theme.spacing(2),
  },
  productItem: {
    marginBottom: theme.spacing(1),
  },
}));

function Orders(props) {
  const classes = useStyles();
  const { history } = props;

  const handleOrderUpdate = () => {
    const analytics = (window && window.metarouter) || (window && window.analytics);
    if (analytics) {
      analytics.track('Order Updated', {
        affiliation: 'Metashop',
        order_id: 'ORDER-1234',
        total: 174.99,
        revenue: 159.99,
        shipping: 10,
        tax: 5,
        discount: 0,
        coupon: 'metazero',
        currency: 'USD',
        products: [
          {
            product_id: 'boot-001',
            sku: 'boot-001',
            category: 'Footwear',
            name: 'Classic Boot',
            brand: 'MetaBoot',
            variant: 'Classic',
            price: 89.99,
            quantity: 1,
            url: window.location.href,
            image_url: 'https://example.com/boot.jpg',
          },
          {
            product_id: 'bag-001',
            sku: 'bag-001',
            category: 'Accessories',
            name: 'Leather Bag',
            brand: 'MetaBag',
            variant: 'Classic',
            price: 70.0,
            quantity: 1,
            url: window.location.href,
            image_url: 'https://example.com/bag.jpg',
          },
        ],
      });
    }
  };

  const handleOrderRefund = () => {
    const analytics = (window && window.metarouter) || (window && window.analytics);
    if (analytics) {
      analytics.track('Order Refunded', {
        affiliation: 'Metashop',
        checkout_id: 'CHECKOUT-1234',
        order_id: 'ORDER-1234',
        total: 174.99,
        revenue: 159.99,
        shipping: 10,
        tax: 5,
        discount: 0,
        coupon: 'metazero',
        currency: 'USD',
        products: [
          {
            product_id: 'boot-001',
            sku: 'boot-001',
            category: 'Footwear',
            name: 'Classic Boot',
            brand: 'MetaBoot',
            variant: 'Classic',
            price: 89.99,
            quantity: 1,
            url: window.location.href,
            image_url: 'https://example.com/boot.jpg',
          },
          {
            product_id: 'bag-001',
            sku: 'bag-001',
            category: 'Accessories',
            name: 'Leather Bag',
            brand: 'MetaBag',
            variant: 'Classic',
            price: 70.0,
            quantity: 1,
            url: window.location.href,
            image_url: 'https://example.com/bag.jpg',
          },
        ],
      });
    }
  };

  const handleOrderCancel = () => {
    const analytics = (window && window.metarouter) || (window && window.analytics);
    if (analytics) {
      analytics.track('Order Cancelled', {
        affiliation: 'Metashop',
        order_id: 'ORDER-1234',
        total: 174.99,
        revenue: 159.99,
        shipping: 10,
        tax: 5,
        discount: 0,
        coupon: 'metazero',
        currency: 'USD',
        products: [
          {
            product_id: 'boot-001',
            sku: 'boot-001',
            category: 'Footwear',
            name: 'Classic Boot',
            brand: 'MetaBoot',
            variant: 'Classic',
            price: 89.99,
            quantity: 1,
            position: 1,
            url: window.location.href,
            image_url: 'https://example.com/boot.jpg',
          },
          {
            product_id: 'bag-001',
            sku: 'bag-001',
            category: 'Accessories',
            name: 'Leather Bag',
            brand: 'MetaBag',
            variant: 'Classic',
            price: 70.0,
            quantity: 1,
            position: 2,
            url: window.location.href,
            image_url: 'https://example.com/bag.jpg',
          },
        ],
      });
    }
  };

  const handleHomeClick = () => {
    history.push(ROUTES.HOMEPAGE);
  };

  // Mock orders data
  const orders = [
    {
      id: 'ORDER-1234',
      date: '2024-03-20',
      total: 159.99,
      products: [
        { name: 'Classic Boot', quantity: 1, price: 89.99 },
        { name: 'Leather Bag', quantity: 1, price: 70.0 },
      ],
    },
    // Add more mock orders as needed
  ];

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item>
          <Typography variant="h4">My Orders</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleHomeClick}>
            Back to Shop
          </Button>
        </Grid>
      </Grid>
      {orders.map(order => (
        <Paper key={order.id} className={classes.orderCard}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Order #{order.id}</Typography>
              <Typography variant="body2" color="textSecondary">
                Placed on: {order.date}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">Total: ${order.total.toFixed(2)}</Typography>
            </Grid>
          </Grid>

          <Divider style={{ margin: '16px 0' }} />

          <div className={classes.orderDetails}>
            {order.products.map((product, index) => (
              <Typography key={index} className={classes.productItem}>
                {product.name} x {product.quantity} - ${product.price.toFixed(2)}
              </Typography>
            ))}
          </div>

          <Divider style={{ margin: '16px 0' }} />

          <div>
            <Button
              variant="outlined"
              color="primary"
              className={classes.actionButton}
              onClick={() => handleOrderUpdate(order.id)}
            >
              Update
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              className={classes.actionButton}
              onClick={() => handleOrderRefund(order.id, order.total)}
            >
              Refund
            </Button>
            <Button variant="outlined" className={classes.actionButton} onClick={() => handleOrderCancel(order.id)}>
              Cancel
            </Button>
          </div>
        </Paper>
      ))}
    </div>
  );
}

Orders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.object.isRequired,
};

export default Orders;
