export default {
  boots: [
    {
      id: '286209051',
      name: 'Acrux TR GTX Hiking Boots',
      brand: "Arc'Teryx",
      price: 250.0,
      image: 'https://www.rei.com/media/d4e7a1b5-8e3a-4ab0-bed0-79714f32f4f1?size=784x588',
      category: 'Boots',
    },
    {
      id: '477595996',
      name: 'Nucleo High II GTX Hiking Boots',
      brand: 'La Sportiva',
      price: 209.99,
      image: 'https://www.rei.com/media/abfd0fb9-5e89-47e0-a607-7ae8f4103240?size=784x588',
      category: 'Boots',
    },
    {
      id: '630434199',
      name: 'Quest 4 GORE-TEX Hiking Boots',
      brand: 'Salomon',
      price: 190.0,
      image: 'https://www.rei.com/media/59c9677d-093d-4ade-91dc-7aa7d082a02a?size=784x588',
      category: 'Boots',
    },
    {
      id: '474560660',
      name: 'Vioz GTX Hiking Boots',
      brand: 'Zamberlan',
      price: 279.99,
      image: 'https://www.rei.com/media/d7f48fe7-1d46-49e3-96c3-926089399226?size=784x588',
      category: 'Boots',
    },
    {
      id: '354851467',
      name: 'Renegade GTX Mid Boot',
      brand: 'Lowa',
      price: 245.0,
      image: 'https://www.rei.com/media/5b1afe6c-83cf-40a5-895a-64a8aea8d9b2',
      category: 'Boots',
    },
    {
      id: '405705363',
      name: "Men's Targhee III Boot",
      brand: 'Keen',
      price: 175.0,
      image: 'https://www.rei.com/media/2604d309-5c1d-4d13-bb10-80c18f9a28ad.jpg?size=784x588',
      category: 'Boots',
    },
    {
      id: '976555691',
      name: 'Toula Hiking Boot',
      brand: 'Loro Piana',
      price: 1995.0,
      image:
        'https://media.loropiana.com/HYBRIS/FAL/FAL9232/3916917B-FD64-433D-8FED-A82A30808025/FAL9232_F2S4_MEDIUM.jpg',
      category: 'Boots',
    },
  ],
  tents: [
    {
      id: '393701643',
      name: 'Assault 2 FUTURELIGHT Tent: 2-Person 4-Season',
      brand: 'The North Face',
      price: 799.95,
      image: 'https://content.backcountry.com/images/items/1200/TNF/TNFZBKS/SUGOTIGR.jpg',
      category: 'Tents',
    },
    {
      id: '843762262',
      name: 'Tungsten Tent: 1-Person 3-Season',
      brand: 'Marmot',
      price: 192.95,
      image: 'https://content.backcountry.com/images/items/1200/MAR/MAR014B/BLA.jpg',
      category: 'Tents',
    },
    {
      id: '730300108',
      name: 'Vagabond XL Rooftop Tent + Annex: 4-Person',
      brand: 'ROAM Adventure Co',
      price: 2848.95,
      image: 'https://content.backcountry.com/images/items/1200/RAO/RAO001B/BLABLA.jpg',
      category: 'Tents',
    },
    {
      id: '862349663',
      name: 'Tepui Ruggedized Autana 3 Tent',
      brand: 'Thule',
      price: 2999.95,
      image: 'https://www.rei.com/media/2b691a02-5dec-4962-8c89-c51d0cf4c59c?size=784x588',
      category: 'Tents',
    },
    {
      id: '803609329',
      name: 'Blacktail Hotel 3 Tent',
      brand: 'Big Agnes',
      price: 279.95,
      image: 'https://www.rei.com/media/8db19ebc-a955-4e8a-b10e-d09e533a8f67?size=784x588',
      category: 'Tents',
    },
  ],
  packs: [
    {
      id: '584430185',
      name: 'Deva 70 Pack',
      brand: 'Gregory',
      price: 329.95,
      image: 'https://www.rei.com/media/9861f0e4-522e-41b8-894e-051d3e1ce317?size=784x588',
      category: 'Packs',
    },
    {
      id: '274345950',
      name: 'Terra 65L',
      brand: 'The North Face',
      price: 188.95,
      image: 'https://content.backcountry.com/images/items/1200/TNF/TNFZAHB/AGGRSUSPGR.jpg',
      category: 'Packs',
    },
    {
      id: '738224379',
      name: 'AMG 105L Backpack',
      brand: 'Mountain Hardwear',
      price: 419.95,
      image: 'https://content.backcountry.com/images/items/1200/MHW/MHWZ9G2/ALPRED.jpg',
      category: 'Packs',
    },
    {
      id: '686039303',
      name: 'Aether Plus 100 Pack',
      brand: 'Osprey',
      price: 400.0,
      image: 'https://www.rei.com/media/681a4352-47cf-4d25-8b6d-8753f98faa17?size=784x588',
      category: 'Packs',
    },
    {
      id: '875553981',
      name: 'Granville 16L Zip Backpack',
      brand: "Arc'teryx",
      price: 180.0,
      image: 'https://content.backcountry.com/images/items/1200/ARC/ARC00X5/YUK.jpg',
      category: 'Packs',
    },
  ],
};
