import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, TextField, Button, Typography, Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Redirect, useHistory } from 'react-router-dom';
import ROUTES from '../configs/routes';
import VOUCHERS from '../configs/vouchers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    maxWidth: 1200,
    margin: '0 auto',
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  summary: {
    position: 'sticky',
    top: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  couponInput: {
    marginRight: theme.spacing(2),
  },
  appliedCoupon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    backgroundColor: '#e8f5e9',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  removeButton: {
    padding: theme.spacing(0.5),
  },
}));

function Checkout({ cartItems, cartTotal, user, updateCart }) {
  const classes = useStyles();
  const history = useHistory();
  const [couponCode, setCouponCode] = useState('');
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [paymentInfoEntered, setPaymentInfoEntered] = useState(false);

  // Add state for tracking IDs
  const [orderID] = useState(`ORDER-${Date.now()}`);
  const [checkoutID] = useState(`CHECKOUT-${Date.now()}`);
  const [cartID] = useState(`CART-${Date.now()}`);

  useEffect(() => {
    const analytics = (window && window.metarouter) || (window && window.analytics);
    if (analytics) {
      // Track Page View
      analytics.page(
        'Checkout',
        'Checkout',
        {
          path: '/checkout',
          title: 'Checkout - Metashop',
          url: window.location.href,
          category: 'Checkout',
        },
        user?.name && user?.email
          ? {
              traits: {
                name: user.name,
                email: user.email,
              },
            }
          : undefined
      );

      // Track Checkout Started
      const prods = [];
      Object.keys(cartItems).forEach(key => {
        prods.push({
          product_id: cartItems[key].id,
          name: cartItems[key].name,
          price: cartItems[key].price,
          quantity: cartItems[key].quantity,
          image_url: cartItems[key].image,
          category: cartItems[key].category,
          brand: cartItems[key].brand,
        });
      });

      analytics.track(
        'Checkout Started',
        {
          affiliation: 'Metashop',
          currency: 'USD',
          order_id: orderID,
          discount: 0,
          revenue: cartTotal - discount,
          shipping: 3,
          tax: 2,
          total: cartTotal - discount + 3 + 2,
          products: prods,
        },
        user?.name && user?.email
          ? {
              traits: {
                name: user.name,
                email: user.email,
              },
            }
          : undefined
      );
    }
  }, []); // Empty dependency array means this runs once on mount

  // Redirect if cart is empty
  if (!cartItems || Object.keys(cartItems).length === 0) {
    return <Redirect to={ROUTES.HOMEPAGE} />;
  }

  const calculateDiscount = (coupon, total) => {
    if (coupon.type === 'percentage') {
      return total * coupon.discount;
    }
    return coupon.discount;
  };

  const handlePaymentInfoChange = () => {
    if (!paymentInfoEntered) {
      setPaymentInfoEntered(true);
      window.analytics?.track('Payment Info Entered', {
        checkout_id: checkoutID,
        order_id: orderID,
      });
    }
  };

  const handleCouponInputChange = e => {
    const value = e.target.value;
    setCouponCode(value);
  };

  const handleApplyCoupon = () => {
    const analytics = (window && window.metarouter) || (window && window.analytics);

    // Track coupon entered event first
    if (analytics) {
      analytics.track('Coupon Entered', {
        order_id: orderID,
        cart_id: cartID,
        coupon_id: couponCode.toUpperCase(),
      });
    }

    const voucher = VOUCHERS[couponCode.toUpperCase()];
    if (voucher) {
      const discountAmount = calculateDiscount(voucher, cartTotal);
      setDiscount(discountAmount);
      setAppliedCoupon(couponCode.toUpperCase());

      // Track successful coupon application
      if (analytics) {
        analytics.track('Coupon Applied', {
          coupon_code: couponCode.toUpperCase(),
          discount_amount: discountAmount,
          currency: 'USD',
          order_value: cartTotal,
          discount_type: voucher.type,
        });
      }

      setCouponCode(''); // Clear input after applying
    } else {
      // Track failed coupon attempt
      if (analytics) {
        analytics.track('Coupon Denied', {
          coupon_code: couponCode.toUpperCase(),
          currency: 'USD',
          order_value: cartTotal,
          reason: 'invalid_code',
        });
      }
    }
  };

  const handleRemoveCoupon = () => {
    setDiscount(0);
    setAppliedCoupon(null);

    // Track coupon removed
    window.analytics?.track('Coupon Removed', {
      coupon_code: appliedCoupon,
      currency: 'USD',
    });
  };

  const handleCheckout = () => {
    // Track order completion
    window.analytics?.track('Order Completed', {
      affiliation: 'Metashop',
      checkout_id: checkoutID,
      order_id: orderID,
      coupon: couponCode || 'metazero',
      currency: 'USD',
      discount: discount,
      products: Object.values(cartItems).map(item => ({
        category: item.category,
        image_url: item.image,
        name: item.name,
        price: item.price,
        product_id: item.id,
        quantity: item.quantity,
        sku: item.id,
        url: window.location.href,
      })),
      revenue: cartTotal,
      shipping: 3,
      tax: 2,
      total: cartTotal - discount + 3 + 2,
    });

    // Clear the cart by updating with empty object and 0 total
    updateCart({}, 0);

    history.push(ROUTES.ORDER_CONFIRMATION);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>
              Shipping Information
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField required id="firstName" name="firstName" label="First name" fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required id="lastName" name="lastName" label="Last name" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField required id="address" name="address" label="Address" fullWidth />
              </Grid>
              {/* Add more address fields as needed */}
            </Grid>
          </Paper>

          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>
              Payment Method
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField required id="cardNumber" label="Card number" fullWidth onChange={handlePaymentInfoChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required id="expDate" label="Expiry date" fullWidth onChange={handlePaymentInfoChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required id="cvv" label="CVV" fullWidth onChange={handlePaymentInfoChange} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper className={`${classes.paper} ${classes.summary}`}>
            <Typography variant="h5" gutterBottom>
              Order Summary
            </Typography>
            {Object.values(cartItems).map(item => (
              <div key={item.id}>
                <Typography>
                  {item.name} x {item.quantity} - ${(item.price * item.quantity).toFixed(2)}
                </Typography>
              </div>
            ))}
            <Divider className={classes.divider} />

            <Grid container spacing={2}>
              {!appliedCoupon ? (
                <>
                  <Grid item xs={8}>
                    <TextField
                      label="Coupon Code"
                      value={couponCode}
                      onChange={handleCouponInputChange}
                      className={classes.couponInput}
                      placeholder="Try SAVE20, SAVE50, or FLAT25"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button variant="outlined" color="primary" onClick={handleApplyCoupon} disabled={!couponCode}>
                      Apply
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <div className={classes.appliedCoupon}>
                    <Typography variant="body2">Coupon applied: {appliedCoupon}</Typography>
                    <IconButton className={classes.removeButton} size="small" onClick={handleRemoveCoupon}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>
                </Grid>
              )}
            </Grid>

            <Divider className={classes.divider} />

            <Typography variant="subtitle1">Subtotal: ${cartTotal.toFixed(2)}</Typography>
            {discount > 0 && (
              <Typography variant="subtitle1" color="error">
                Discount: -${discount.toFixed(2)}
              </Typography>
            )}
            <Typography variant="h6">Total: ${(cartTotal - discount).toFixed(2)}</Typography>

            <Button variant="contained" color="primary" fullWidth style={{ marginTop: 16 }} onClick={handleCheckout}>
              Complete Purchase
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

Checkout.propTypes = {
  cartItems: PropTypes.object.isRequired,
  cartTotal: PropTypes.number.isRequired,
  user: PropTypes.object,
  updateCart: PropTypes.func.isRequired,
};

export default Checkout;
