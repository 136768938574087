import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: 100,
    right: 0,
    left: 0,
    width: 900,
    maxHeight: 'calc(100% - 200px)',
    margin: '0 auto',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #009E7F',
    borderRadius: '6px',
    outline: 'none',
    overflow: 'scroll',
  },
}));

function ProductModal(props) {
  let { product, open, handleClose, onAddToCart } = props;
  const classes = useStyles();

  const body = (
    <div className={classes.paper}>
      <div className="product-modal-card">
        <div className="product-modal">
          <div className="product-modal-preview">
            <img className="product-modal-preview-image" src={product.image} draggable="false" alt={product.slag} />
          </div>
          <div className="product-modal-details-container">
            <div className="product-modal-details-wrapper">
              <div className="product-modal-details-header">
                <h1 className="product-modal-name">{product.name}</h1>
                <div className="product-modal-details-price-wrapper">
                  <div className="product-modal-details-price">${product.price}</div>
                </div>
              </div>
              <div className="product-modal-details-unit">{product.unit}</div>
              <p className="product-modal-details-brand">{product.brand}</p>
              <div className="product-modal-action-container">
                <div className="product-modal-action-wrapper">
                  <button
                    type="button"
                    className={`product-card-cart-btn`}
                    onClick={() => {
                      onAddToCart(product);
                    }}
                  >
                    <span className="btn-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14.4" height="12" viewBox="0 0 14.4 12">
                        <g data-name="Group 120" transform="translate(-288 -413.89)">
                          <path
                            data-name="Path 154"
                            fill="currentColor"
                            d="M298.7,418.289l-2.906-4.148a.835.835,0,0,0-.528-.251.607.607,0,0,0-.529.251l-2.905,4.148h-3.17a.609.609,0,0,0-.661.625v.191l1.651,5.84a1.336,1.336,0,0,0,1.255.945h8.588a1.261,1.261,0,0,0,1.254-.945l1.651-5.84v-.191a.609.609,0,0,0-.661-.625Zm-5.419,0,1.984-2.767,1.98,2.767Zm1.984,5.024a1.258,1.258,0,1,1,1.319-1.258,1.3,1.3,0,0,1-1.319,1.258Zm0,0"
                          />
                        </g>
                      </svg>
                    </span>
                    <span className="btn-text">&nbsp;&nbsp;Cart</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      className="cart-content-modal"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
}

ProductModal.propTypes = {
  product: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onAddToCart: PropTypes.func.isRequired,
};

export default ProductModal;
