import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import MRLogo from '../assets/mr_logo.png';
import MetaShop from '../assets/metashop.png';
import ROUTES from '../configs/routes';

function Header(props) {
  const { isUserLoggedIn, onJoin } = props;
  const history = useHistory();

  const handleOrdersClick = () => {
    history.push(ROUTES.ORDERS);
  };

  return (
    <div className="header">
      <div className="header-title-container">
        <img className="header-logo" src={MetaShop} height="50px" alt="MetaShop Logo" />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        {isUserLoggedIn && (
          <Button variant="outlined" color="primary" onClick={handleOrdersClick}>
            Orders
          </Button>
        )}
        {isUserLoggedIn ? (
          <img className="header-user" src={MRLogo} alt="user" height="38px" />
        ) : (
          <div className="login-btn" onClick={() => onJoin()}>
            Join
          </div>
        )}
      </div>
    </div>
  );
}

Header.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  onJoin: PropTypes.func.isRequired,
};

export default Header;
