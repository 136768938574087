import React from 'react';
import PropTypes from 'prop-types';
import lifecycle from 'react-pure-lifecycle';
import { deleteEntryFromDB } from '../configs/indexedDB';

const componentDidMount = () => {
  function deleteAllCookiesAndRefresh() {
    try {
      deleteEntryFromDB('local-ajs.js');
      deleteEntryFromDB('custom-tags');
      console.log('File successfully deleted');
    } catch (error) {
      console.error('Error:', error);
    }
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf('=');
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
    window.location.href = '/shop';
  }
  deleteAllCookiesAndRefresh();
};

const methods = {
  componentDidMount,
};

function Reset() {
  return (
    <div>
      <h1>Resetting Cookies</h1>
    </div>
  );
}

Reset.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default lifecycle(methods)(Reset);
